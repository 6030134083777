import { useState, useEffect } from "react";

const ProductBox = (params) => {
  const [seconds, setSeconds] = useState(params.loadingTime || null);

  useEffect(() => {
    if (params.loading === false) {
      setSeconds(null);
    }
  }, [params.loading]);

  useEffect(() => {
    if (seconds > 0) {
      const interval = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [seconds]);

  const Badge = ({ label, classes, type, icon }) => {
    const types = {
      category: "text-info-800 bg-info-100",
      manufacturer: "text-white bg-purple-500",
      material: "text-white bg-lime-600",
      labels: "text-white bg-slate-600",
    };

    return (
      <span
        className={`inline-block rounded-[0.27rem] px-[0.65em] pb-[0.75em] pt-[0.7em] text-center align-baseline text-[0.9em] font-bold leading-none relative z-10 ${
          type === "category" && [true,false].includes(icon) ? icon === true ? "bg-lime-700 text-white" : "bg-red-700 text-white" : types?.[type] || "text-info-800 bg-info-100"
        } ${classes}`}
      >
        {label}
        {icon ? (
          <>
            <span
              className={`whitespace-nowrap rounded-full text-center align-baseline text-[0.75em] font-bold leading-none absolute -top-1.5 -right-1.5  text-white p-0.5 ${
                icon ? "bg-lime-600" : "bg-red-700"
              }`}
            >
              {icon === true ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-3 h-3"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M4.5 12.75l6 6 9-13.5"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-3 h-3"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </span>
          </>
        ) : null}
      </span>
    );
  };

  const labels = () => {
    if (params.product.labels) {
      const badges = [];
      let i = 0;
      for (const [key, value] of Object.entries(params.product.labels)) {
        badges.push(
          <Badge
            key={`label-${i}`}
            label={`${key}: ${value}`}
            type="labels"
            classes={"mr-[0.25rem]"}
          />
        );

        i++;
      }

      return badges;
    }
  };

  if (params.loading) {
    return (
      <div className="block bg-slate-50 p-[0.5rem] mb-[1rem] w-5/6 m-auto mt-5">
        <div className="justify-between flex">
          <div
            className="product-loader inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
          <div className="left w-2/5 rounded-sm bg-slate-200 h-100"></div>
          <div className="right w-3/5 pl-8">
            <div className="text-left font-medium">Product Box</div>
            <div className="items-start">
              <div className="rounded-sm bg-slate-200 h-5 w-100 mb-1"></div>
              <div className="rounded-sm bg-slate-200 h-5 w-60 mb-1"></div>
              <div className="rounded-sm bg-slate-200 h-5 w-50 mb-1"></div>
              <div className="rounded-sm bg-slate-200 h-5 w-80 mb-1"></div>
              <div className="rounded-sm bg-slate-200 h-5 w-100 mb-1"></div>
              <div className="rounded-sm bg-slate-200 h-5 w-60 mb-1"></div>
              <div className="rounded-sm bg-slate-200 h-5 w-50 mb-1"></div>
              <div className="rounded-sm bg-slate-200 h-5 w-80 mb-1"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="block bg-slate-50 p-[0.5rem] mb-[1rem] w-5/6 m-auto mt-5">
      <div className="justify-between flex">
        <div className="left w-2/5">
          {params.product.alibabaUrl ? (
            <a
              href={params.product.alibabaUrl}
              target="_blank"
              rel="noreferrer"
            >
              <img src={params.product.image} alt="" />
            </a>
          ) : (
            <img src={params.product.image} alt="" />
          )}
        </div>
        <div className="right w-3/5 pl-8">
          <div className="text-left font-medium">{params.product.prodName || 'Product box'}</div>
          <div className="items-start">
            <div className="text-left text-[13px]">Probability: 100%</div>
            {params.product["10digit"] ? (
              <div className="text-left text-[13px]">
                HS Code: {params.product["10digit"]}
              </div>
            ) : null}

            {params.product.description_en ? (
              <div className="text-left text-[13px]">
                Description (EN): {params.product.description_en}
              </div>
            ) : null}

            {params.product.description_hu ? (
              <div className="text-left text-[13px]">
                Description (HU): {params.product.description_hu}
              </div>
            ) : null}

            {params.product.material_hu || params.product.material_en ? (
              <div className="text-left text-[13px]">
                Material (EN): {params.product.material_en}
                Material (HU): {params.product.material_hu}
              </div>
            ) : null}

            {params.product.olafprice ? (
              <div className="text-left text-[13px]">
                OLAF Price: {params.product.olafprice}
              </div>
            ) : null}

            {params.product.duty ? (
              <div className="text-left text-[13px]">
                Duty: {params.product.duty}
              </div>
            ) : null}
            
            {params.product.fq && params.product.fq > 1 ? (
              <div className="text-left text-[13px]">
                FQ: {params.product.fq}
              </div>
            ) : null}

            {params.product.category ? (
              <div className="text-left text-[13px]">Category:</div>
            ) : null}

            <div className="text-left text-[13px] leading-[2rem]">
              {params.product.categoryLvl1 ? (
                <Badge
                  label={params.product.categoryLvl1}
                  type="category"
                  classes={"mr-[0.25rem]"}
                  icon={params.product.cat1Passed || null}
                />
              ) : (
                "N/A"
              )}
              {params.product.categoryLvl2 ? (
                <Badge
                  label={params.product.categoryLvl2}
                  type="category"
                  classes={"mr-[0.25rem]"}
                  icon={params.product.cat2Passed || null}
                />
              ) : (
                "N/A"
              )}
              {params.product.categoryLvl3 ? (
                <Badge
                  label={params.product.categoryLvl3}
                  type="category"
                  classes={"mr-[0.25rem]"}
                  icon={params.product.cat3Passed || null}
                />
              ) : (
                "N/A"
              )}
              {params.product.categoryLvl4 ? (
                <Badge
                  label={params.product.categoryLvl4}
                  type="category"
                  classes={"mr-[0.25rem]"}
                  icon={params.product.cat4Passed || null}
                />
              ) : (
                "N/A"
              )}
            </div>

            {params.product.manufacturer ? (
              <div className="text-left text-[13px] leading-[2rem]">
                Manufacturer / Brand:
                <Badge
                  label={params.product.manufacturer}
                  type="manufacturer"
                  classes={"mr-[0.25rem]"}
                />
              </div>
            ) : null}

            {params.product.material ? (
              <div className="text-left text-[13px] leading-[2rem]">
                Material(s):
                {params.product.material.split(',').map((mat) => (
                  <Badge
                  label={mat}
                  type="material"
                  classes={"mr-[0.25rem]"}
                />
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="text-left text-[13px] leading-[2rem]">
        Labels: <br />
        {params.product.labels ? labels().map((item, i) => item) : "N/A"}
      </div>
    </div>
  );
};

export default ProductBox;
