import { Formik } from "formik";
import "./App.css";
import { TEInput, TERipple } from "tw-elements-react";
import { useState, useEffect } from "react";
import axios from "axios";
import ProductBox from "./components/product-box";
import RelatedProductBox from "./components/related-product-box";

function App() {
  const [loading, setLoading] = useState(false);
  const [loadingTime, setLoadingTime] = useState(false);
  const [productResults, setProductResults] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState(false);

  const initialValues = {
    loginEmail: "",
    loginPassword: "",
    productUrl: "",
    productImage: null,
  };

  const checkproductByUrl = async (url) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_APIURL}/v1/image-recognizer/recognize`,
        {
          imageUrl: url,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.data;

      setProductResults(data);

      return data === undefined ? null : data;
    } catch (e) {
      console.log(e);
    }
  };

  const checkproductByUpload = async (url) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_APIURL}/v1/image-recognizer/upload`,
        {
          imageUrl: url,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.data;

      setProductResults(data.originalProduct);

      return data === undefined ? null : data;
    } catch (e) {
      console.log(e);
    }
  };

  const handleFileUpload = async (file) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_APIURL}/v1/image-recognizer/upload`,
        {
          productImage: file,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = await response.data;

      setProductResults(data.originalProduct);
      setRelatedProducts(data.relevantProducts || false);

      return data === undefined ? null : data;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    /**
     * If the course is opened for the first time
     * Change ActiveStep to 1
     */
    // checkproductByUrl();
  }, []);

  const passClickHandler = async (values) => {
    console.log(values);

    setLoading(true);
    setLoadingTime(60);
    if (values.productUrl) {
      await checkproductByUrl(values.productUrl);
    } else if (values.productImage) {
      await handleFileUpload(values.productImage);
    }
    setLoading(false);
    /*
    dispatch(forgotPassword({ email: values.loginEmail }))
      .then((response) => {
        dispatch(
          showMessage({
            message: `${t("login.FORGOT_PASSWORD_EMAIL_SENT")}`,
            variant: "success",
          })
        );
      })
      .finally(() => {
        setDialog(false);
      });
    */
  };

  return (
    <div className="App">
      <section className="h-screen">
        <div className="">
          <header className="bg-slate-100 flex justify-between py-1.5 px-2.5 border-b items-center">
            <div className="logo text-lg text-neutral-600">AI PlayGround</div>
            <div className="searchbar">
              <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                  console.log(values);

                  passClickHandler(values);
                }}
              >
                {(formik) => (
                  <div className="mb-12 md:mb-0 md:w-full lg:w-4/12 xl:w-4/12 flex items-center justify-between">
                    {/* Email input */}
                    <div
                      className="relative mb-6 hidden"
                      data-te-input-wrapper-init
                    >
                      <TEInput
                        label={"Image url"}
                        type="text"
                        name="productUrl"
                        onKeyUp={(e) =>
                          formik.setFieldValue("productUrl", e.target.value)
                        }
                      />
                      <TERipple rippleColor="light">
                        <button
                          type="button"
                          className="inline-block rounded bg-amber-500 px-7 pb-2.5 pt-3 text-sm font-medium  leading-normal text-white transition duration-150 ease-in-out hover:bg-primary-600 focus:bg-primary-600 focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                          style={{ background: "#ff9f0c" }}
                          data-te-ripple-init
                          data-te-ripple-color="light"
                          onClick={() => formik.submitForm()}
                        >
                          Recognize
                        </button>
                      </TERipple>
                    </div>

                    <div className="flex items-center justify-center text-[12px]">
                      <div className="w-96">
                        <label
                          htmlFor="formFile"
                          className="mb-2 inline-block text-neutral-700 dark:text-neutral-200"
                        >
                          Upload product image
                        </label>
                        <input
                          className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary bg-slate-50"
                          type="file"
                          id="formFile"
                          name="productImage"
                          accept="image/png, image/gif, image/jpeg"
                          onChange={(event) => {
                            formik.setFieldValue(
                              "productImage",
                              event.target.files[0]
                            );
                            formik.setFieldValue("productUrl", null);
                            formik.submitForm();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Formik>
            </div>
          </header>
          {/* Left column container with background*/}
          <div className="mainbox g-6 flex h-full flex-wrap items-center justify-center mx-12 mt-16">
            <div className="w-10/12">
              {loading || productResults.length ? (
                <>
                  {console.log(productResults)}
                  {productResults.map((productResult) => 
                    <ProductBox
                      product={productResult}
                      loading={loading}
                      loadingTime={loadingTime}
                    />,
                  )}

                  {relatedProducts ? (
                    <>
                      <div className="my-[2rem] text-xl">
                        Relevant products:
                      </div>{" "}
                      {relatedProducts.map((item, i) => (
                        <RelatedProductBox
                          key={`related-${i}`}
                          product={item}
                          loading={loading}
                        />
                      ))}{" "}
                    </>
                  ) : null}
                </>
              ) : (
                <img
                  src="https://tecdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
                  className="w-6/12 inline-block mt-24"
                  alt="Sample"
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
